<template>
  <div>
    <div class="mt-6">
      <div class="font-bold text-center text-darkblue">
        <h4 class="text-darkblue capitalize">Settings</h4>
      </div>
    </div>
    <div class="md:justify-end justify-center flex flex-row  mt-3 mb-3">
      <div class="text-center mb-2">
        <button
          @click="mode = 'settings'"
          :class="{
            'bg-darkblue': mode == 'settings',
            'text-white': mode == 'settings',
            'text-darkblue': mode != 'settings',
          }"
          class="pl-3 text-xs mr-2 pr-3 border-2 border-darkblue hover:bg-darkblue hover:text-white pt-1 pb-1 rounded-full"
          type="button"
        >
          App Settings
        </button>
      </div>
      <div class="text-center mb-2">
        <button
          @click="mode = 'profile'"
          :class="{
            'bg-darkblue': mode == 'profile',
            'text-white': mode == 'profile',
            'text-darkblue': mode != 'profile',
          }"
          class="pl-3 text-xs mr-2 pr-3 border-2 border-darkblue hover:border-darkblue hover:bg-darkblue hover:text-white pt-1 pb-1 rounded-full"
          type="button"
        >
          Profile
        </button>
      </div>
      <div class="text-center mb-2">
        <button
          @click="mode = 'password'"
          :class="{
            'bg-darkblue': mode == 'password',
            'text-white': mode == 'password',
            'text-darkblue': mode != 'password',
          }"
          class="pl-3 text-xs   pr-3 border-2 border-darkblue hover:border-darkblue hover:bg-darkblue hover:text-white pt-1 pb-1 rounded-full"
          type="button"
        >
          Change Password
        </button>
      </div>
    </div>
    <div class="text-center mt-1 mb-2">
      <font-awesome-icon
        v-if="loadingQuery"
        title="loading"
        :class="'text-dark animate-spin text-base mr-1 '"
        :icon="['fas', 'spinner']"
      />
    </div>
    <div
      class="ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"
    >
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(SubmitSettings)" class="">
            <div
              v-if="mode == 'settings'"
              class="w-full ml-auto mr-auto md:w-5/12 mb-3 md:mb-6 "
            >
              <label class="block md:text-xs" for="vat Percentage"
                >Vat Percentage
              </label>
              <validation-provider rules="double|required" v-slot="{ errors }">
                <input
                  type="text"
                  v-model="settings.vatPercentage"
                  name="Vat Percentage"
                  placeholder="Example 2.1"
                  class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                />
                <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div v-if="mode == 'profile'">
              <div class=" justify-around flex flex-col md:flex-row">
                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="FullName"
                    >Full Name <span class="text-red-700">*</span>
                  </label>
                  <validation-provider
                    rules="required|custom_text"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="user.name"
                      name="Full Name"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="Amount"
                    >Phone Number <span class="text-red-700">*</span>
                  </label>
                  <validation-provider
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      v-model="user.phoneNumber"
                      name="Phone Number"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="  justify-around flex flex-col md:flex-row">
                <div class="w-full mb-3 md:mb-6  md:w-5/12 ">
                  <label class="block md:text-xs" for="FullName"
                    >Email <span class="text-red-700">*</span>
                  </label>
                  <validation-provider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <input
                      type="email"
                      v-model="user.email"
                      name="Email"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="Amount">Address </label>
                  <validation-provider rules="custom_text" v-slot="{ errors }">
                    <input
                      type="text"
                      v-model="user.address"
                      name="Address"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
              <div class="  justify-around flex flex-col md:flex-row">
                <div class="w-full mb-3 md:mb-6  md:w-5/12 ">
                  <label class="block md:text-xs" for="FullName"
                    >Position
                  </label>

                  <input
                    type="text"
                    disabled
                    v-model="user.position"
                    name="Position"
                    class="border-2 bg-gray-200 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                  />
                </div>
                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="Role">Role </label>
                  <input
                    disabled
                    type="text"
                    v-model="user.roleId.name"
                    name="Role"
                    class="border-2 bg-gray-200 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                  />
                </div>
              </div>
            </div>
            <div v-if="mode == 'password'">
              <div class=" justify-around flex flex-col md:flex-row">
                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="Old Password"
                    >Old password <span class="text-red-700">*</span>
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      :type="!!showPassword ? 'text' : 'password'"
                      v-model="password.oldPassword"
                      name="Old Password"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
                  <label class="block md:text-xs" for="New Password"
                    >New password <span class="text-red-700">*</span>
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input
                      :type="!!showPassword ? 'text' : 'password'"
                      v-model="password.newPassword"
                      name="New Password"
                      class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
                    />
                    <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
                  </validation-provider>
                  <div class="mb-4">
                    <input
                      class="mr-1"
                      v-model="showPassword"
                      type="checkbox"
                      name=""
                      id="showPassword"
                    />
                    <label class="text-xs " for="showPassword">
                      Show password
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="text-white block md:text-xs bg-black hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
            >
              <svg
                v-if="loadingSpinner"
                class="inline animate-spin mr-2"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
              Save Setting
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import { mapMutations } from "vuex";

  import { ValidationProvider, ValidationObserver } from "vee-validate";

  export default {
    name: "Settings",
    props: {},
    components: { FontAwesomeIcon, ValidationProvider, ValidationObserver },
    apollo: {
      getSettings: {
        query: gql`
          query getSettings {
            getSettings {
              _id
              vatPercentage
            }
          }
        `,
        variables() {
          return {};
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSettings = true;
            return;
          }

          this.loadingSettings = false;
        },
      },
      getLoggedInUser: {
        query: gql`
          query getLoggedInUser {
            getLoggedInUser {
              _id
              name
              email
              address
              phoneNumber
              position
              roleId {
                _id
                name
              }
            }
          }
        `,
        variables() {
          return {};
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingUser = true;
            return;
          }

          this.loadingUser = false;
        },
      },
    },
    data() {
      return {
        getSettings: {},
        loadingUser: false,
        showPassword: false,
        loadingSettings: false,
        mode: "settings",
        settings: {
          vatPercentage: "",
        },
        password: {
          newPassword: "",
          oldPassword: "",
        },
        getLoggedInUser: {},
        user: {
          name: "",
          email: "",
          address: "",
          phoneNumber: "",
        },
        loadingSpinner: false,
      };
    },
    computed: {
      updateErrorMessage: function() {
        let msg;
        switch (this.mode) {
          case "settings":
            msg = `Error updating your settings,check your network or contact your developer`;
            break;
          case "profile":
            msg = `Error updating your profile,check your network or contact your developer`;
            break;
          case "password":
            msg = `Error changing your password,check your network or contact your developer`;
            break;
          default:
            break;
        }
        return msg;
      },
      updateMessage: function() {
        let msg;
        switch (this.mode) {
          case "settings":
            msg = `Your settings have been updated successfully`;
            break;
          case "profile":
            msg = `Your profile has been updated successfully`;
            break;
          case "password":
            msg = `Your password has been updated successfully`;
            break;
          default:
            break;
        }
        return msg;
      },
      loadingQuery: function() {
        return this.loadingSettings == true || this.loadingUser == true;
      },
    },
    watch: {
      getSettings: function(newValue) {
        this.settings = newValue;
      },
      getLoggedInUser: function(newValue) {
        this.user = newValue;
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      async UpdateSettings() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $settings: SettingsInput!) {
                updateSettings(id: $id, settings: $settings) {
                  _id
                  vatPercentage
                }
              }
            `,
            // Parameters
            variables: {
              id: this.settings._id,
              settings: { vatPercentage: Number(this.settings.vatPercentage) },
            },
          });

          if (!errors && data.updateSettings["_id"]) {
            this.loadingSpinner = false;
            return this.$emit("show-alert", {
              type: "success",
              message: this.updateMessage,
            });
          }

          if (!!errors && data == null) {
            this.loadingSpinner = false;

            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
          this.loadingSpinner = false;
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
        }
      },
      async UpdateProfile() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($user: UpdateProfileInput!) {
                updateCurrentUser(user: $user) {
                  _id
                  name
                  address
                }
              }
            `,
            // Parameters
            variables: {
              user: {
                name: this.user.name,
                address: this.user.address,
                phoneNumber: this.user.phoneNumber,
                email: this.user.email,
              },
            },
          });

          if (!errors && data.updateCurrentUser["_id"]) {
            this.loadingSpinner = false;
            return this.$emit("show-alert", {
              type: "success",
              message: this.updateMessage,
            });
          }

          if (!!errors && data == null) {
            this.loadingSpinner = false;

            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
          this.loadingSpinner = false;
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
        }
      },
      async ChangePassword() {
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($oldPassword: String!, $newPassword: String!) {
                changePassword(
                  oldPassword: $oldPassword
                  newPassword: $newPassword
                ) {
                  _id
                  name
                  address
                }
              }
            `,
            // Parameters
            variables: {
              oldPassword: this.password.oldPassword,
              newPassword: this.password.newPassword,
            },
          });

          if (!errors && data.changePassword["_id"]) {
            this.loadingSpinner = false;
            return this.$emit("show-alert", {
              type: "success",
              message: this.updateMessage,
            });
          }

          if (!!errors && data == null) {
            this.loadingSpinner = false;

            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
          this.loadingSpinner = false;
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: this.updateErrorMessage,
          });
        }
      },
      NavigateToSingleUser(id) {
        this.$router.push({ name: "singleUser", params: { id: id } });
      },
      RefreshTable() {
        this.searchData.filter = null;
        this.searchData.searchQuery = null;
      },
      SearchTable() {
        this.searchData.filter = this.searchFilter;
        this.searchData.searchQuery = this.searchQuery;
      },

      SubmitSettings() {
        switch (this.mode) {
          case "settings":
            this.UpdateSettings();
            break;
          case "profile":
            this.UpdateProfile();
            break;
          case "password":
            this.ChangePassword();

            break;

          default:
            break;
        }
      },
      FormatTableData(data) {
        this.table_data = data.map((user) => {
          let date = new Date(Number(user.createdAt));

          return {
            id: user._id,
            name: user.name,
            position: user.position,
            role: user.roleId.name,
            phoneNumber: user.phoneNumber,
            regDate: `${date.getDate()}/${date.getMonth() +
              1}/${date.getFullYear()}`,
            btn: "btn",
          };
        });
      },
      TableButton() {
        this.$router.push({ name: "manageUser", params: { mode: "new" } });
      },
    },
    mounted() {
      this.setBreadcrumbs([{ displayName: "Settings", pathName: "settings" }]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-select.open .custom-select-options {
    display: block;
  }
  .custom-select li.selected {
    background-color: rgb(52, 211, 153);
  }
</style>
